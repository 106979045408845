import { render, staticRenderFns } from "./governmentAdd.vue?vue&type=template&id=0573e900&scoped=true&"
import script from "./governmentAdd.vue?vue&type=script&lang=js&"
export * from "./governmentAdd.vue?vue&type=script&lang=js&"
import style0 from "./governmentAdd.vue?vue&type=style&index=0&id=0573e900&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0573e900",
  null
  
)

export default component.exports