<template>

<!--政府管理中的  新增-->
  <div class="container">
    <div class="body-container">
      <div class="body-section">
        <div class="all-container">
          <div class="all-section">
            <div class="government-container">
              <div class="government-from_section">
                <el-form label-position="right" label-width="100px" :model="basicInfo" :rules="basicInfoRefs" ref="basicInfoRef">
                  <el-form-item label="手机号码" prop="phone">
                    <el-input maxlength="11" v-model="basicInfo.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="名称" prop="sysName">
                    <el-input maxlength="11" v-model="basicInfo.sysName"></el-input>
                  </el-form-item>
                  <el-form-item label="性别" prop="sex">
                    <el-radio-group v-model="basicInfo.sex">
                      <el-radio :label="0">男</el-radio>
                      <el-radio :label="1">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="区域" prop="codeData">
                    <el-cascader  v-model="basicInfo.codeData" :props="props"></el-cascader>
                  </el-form-item>
                </el-form>
                <div class="button-section">
                  <el-button class="add-button" @click="add">提交</el-button>
                  <router-link :to="{name: 'managementGovernment'}">
                    <el-button class="return-button">取消</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdministratorService from '@/globals/service/management/Administrator.js'

export default {
  data () {
    return {
      props: {
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const { level } = node
          let data = []
          setTimeout(() => {
            if (level === 1) {
              AdministratorService.AdministratorUserCity({ provinceId: node.value }).then(res => {
                data = res.data.map(item => {
                  return {
                    value: item.citycode,
                    label: item.city,
                    leaf: level >= 2
                  }
                })
                resolve(data)
              })
              return
            }
            if (level === 2) {
              AdministratorService.AdministratorUserArea({ cityId: node.value }).then(res => {
                data = res.data.map(item => {
                  return {
                    value: item.areacode,
                    label: item.area,
                    leaf: level >= 2
                  }
                })
                resolve(data)
              })
              return
            }
            AdministratorService.AdministratorUserprovince().then(res => {
              data = res.data.map(item => {
                return {
                  value: item.provincecode,
                  label: item.province,
                  leaf: level >= 2
                }
              })
              resolve(data)
            })
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          }, 1000)
        }
      },
      basicInfoRefs: {
        codeData: [
          { required: true, message: '请选择省市区', trigger: 'blur' }
        ],
        sysName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { required: true, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
            trigger: 'blur'
          }
        ]
      },
      basicInfo: {
        phone: '',
        sysName: '',
        codeData: [],
        sex: '',
        areacode: null,
        citycode: null
      }
    }
  },
  created () {
  },
  methods: {
    add () {
      this.$refs.basicInfoRef.validate(valid => {
        if (valid) {
          this.basicInfo.provincecode = this.basicInfo.codeData[0]
          if (this.basicInfo.codeData[1]) this.basicInfo.citycode = this.basicInfo.codeData[1]
          if (this.basicInfo.codeData[2]) this.basicInfo.areacode = this.basicInfo.codeData[2]
          AdministratorService.AdministratorAddGovernment(this.basicInfo).then(res => {
            this.$alert(`账号: ${res.data.phone}; 密码: ${res.data.password}`, '请记住你的账号密码', {
              confirmButtonText: '复制账号密码',
              callback: () => {
                var input = document.createElement('input') // 直接构建input
                input.value = '账号：' + res.data.phone + ' 密码：' + res.data.password // 设置内容
                document.body.appendChild(input) // 添加临时实例
                input.select() // 选择实例内容
                document.execCommand('Copy') // 执行复制
                document.body.removeChild(input) // 删除临时实例
                this.$router.push({ name: 'managementGovernment' })
              }
            })
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  width: 100%;
  min-height: 100%;
  flex: 1;
  background-color: #fff;
  color: #333;
  .body-container{
    padding: 80px 40px 20px 61px;
    .body-section{
      min-width: 450px;
    }
    .basic-container{
      max-width: 450px;
      .el-select{
        width: 100%;
      }
    }
    .all-container{
      .all-section-header{
          width: 100%;
          min-width: 160px;
          height: 50px;
          background-color: #F8F8F8;
          padding:  0 30px;
          border-radius: 6px;
          margin-bottom: 40px;
        .header-title{
          line-height: 50px;
          font-size: 18px;
          color: #333;
        }
      }
      .organization-from_section{
        max-width: 450px;
        /deep/.el-icon-camera{
          font-size: 50px;
          color: #34BBEC;
          margin-top: 50px;

        }
        /deep/.el-upload-dragger{
          width: 350px;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .government-from_section{
        max-width: 450px;
        .el-select{
          width: 100%;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .school-from_section{
        max-width: 450px;
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .class-from_section{
        max-width: 450px;
        .el-select, .el-autocomplete{
          width: 100%;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
    }
  }
}
/deep/.el-popper, .el-select-dropdown {
  .hover{
    background-color: #34BBEC !important;
    color: #fff !important;
  }
}
</style>
